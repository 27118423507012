import React, {useState, useEffect} from 'react';
import { Container, Typography, Grid } from '@mui/material';
import About from '../public/images/about.png'
import Image from 'next/image'
import Link from 'next/link'
import { useAppContext } from '../AppContext';
import { useTheme } from '@mui/material/styles';

export default function HomeAboutUs(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  
  const [themeCss, setThemeCss] = useState(null);
  const [aboutData, setAboutData] = useState('');
  

  const SHOW_MORE_TEXT_LENGTH = 500;  
  
  function removeTags(str) {
    if ((str===null) || (str===''))
      return false;
    else
      str = str.toString();
    return str.replace( /(<([^>]+)>)/ig, '');
  }
  

    useEffect(()=>{
      let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
      setThemeCss(custom_css);

      let x = removeTags(siteData && siteData.aboutPageSections[0].content);
      setAboutData(x);

    }, [themeData])
    
    const HomeAboutUsContainer = {
      pt:{md:10, sm:7, xs:5},
      '& h2':{
          fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
          fontSize:35,
          fontWeight:700,
          pb:5,
          color: theme.palette.primary.title
      },
      '& p':{
        fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
        fontSize:16,
        fontWeight:300,
        textTransform:'capitalize',
        color: theme.palette.primary.location,
      }
    };

    const aboutImageContainer = {
        fontSize: 14,
        fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      '& img':{
          width:'100%'
      }
    }

    const content = {
      fontSize: 16,
      '& a': {
          fontSize: 16,
          fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
          color: theme.palette.primary.main,
          textDecoration:'underline'
      },
      '& span': {
          fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
          color: theme.palette.primary.location,
          fontSize: 14,
          fontWeight: 100,
      },
      '& h5': {
          fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
          color: theme.palette.primary.location,
          fontSize: 14,
          fontWeight: 100,
      },
      '& h6': {
          fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
          color: theme.palette.primary.location,
          fontSize: 14,
          fontWeight: 100,
      },
      '& p': {
          fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
          color: theme.palette.primary.location,
          fontSize: 14,
          fontWeight: 100,
      },
      '& ul': {
          fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
          color: theme.palette.primary.location,
          fontSize: 14,
          fontWeight: 100,
      },
    };
  
  return (
    <>
      {props.data && props.data.length >0 ? (
        <Container maxWidth="lg" sx={HomeAboutUsContainer}>
            <Grid container spacing={{md:8, sm:5, xs:3}}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography variant="h2">About Us</Typography>
                    <Typography component="div"className='desktopMode'>                      
                      <Typography component="div" sx={content}>                      
                        <Typography variant='p'>
                          {aboutData && aboutData.substring(0, SHOW_MORE_TEXT_LENGTH)+'...'}
                          {aboutData && aboutData.length > SHOW_MORE_TEXT_LENGTH && (
                            <Link href={`/about`} style={{ display:"inlineflex", alignItems:"center", color:`${themeCss ? themeCss.theme_main_color : ''}`, marginLeft:10, textTransform:'lowercase'}}>
                              more
                            </Link>
                          )}                  
                        </Typography>
                      </Typography> 
                    </Typography> 
                  <Typography component="div" className='mobileMode'>                      
                    <Typography component="p" sx={content}>                      
                        {aboutData && aboutData.substring(0, 200)+'...'}
                        {aboutData && aboutData.length > 200 && (
                          <Link href={`/about`} style={{ display:"inlineflex", alignItems:"center", color:`${themeCss ? themeCss.theme_main_color : ''}`, marginLeft:10, textTransform:'lowercase'}}>
                            more
                          </Link>
                        )}                  
                    </Typography>
                  </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={aboutImageContainer}>
                    <Image 
                      src={siteData&&siteData.aboutPageSections[0].img_path ? siteData.aboutPageSections[0].img_path : About} 
                      alt="..." 
                      width={'100'} 
                      height={'400'}
                      sizes="(max-width: 325px) 100vw"
                      style={{objectFit: "contain"}}	
                    />
                </Grid>
            </Grid>
        </Container>
      ):("")}
    </>
  );
}
